/* eslint-disable max-len,no-nested-ternary */
/* global G */
import { useContext } from 'react'
import ApplicationContext from '@platform/react/context/application'
import { getMimeGroup, getMimeType } from 'lib/util/attachment'
import { isArr, isBool, isObj } from 'lib/util'
import fileConfig from 'configPlatform/ui/_shared/file.json5'
import typesConfig from 'configPlatform/ui/_shared/types.json5'

const parseComplexType = (complexType, mimeType, group) => {
  const complexMimeTypes = complexType?.types

  if (!complexMimeTypes || !isArr(complexMimeTypes) || !complexMimeTypes.length) {
    throw new TypeError(`Complex mime types not defined correctly. Got: ${JSON.stringify(complexMimeTypes)}`)
  }

  const foundMimetype = complexMimeTypes.find(x => x.includes(mimeType))

  return foundMimetype
    ? !foundMimetype.includes('!')
    : group.includes(mimeType)
}

/**
 * usePreviewableAttachment Hook
 *
 * Use to determine if {@param attachment} is previewable. It does so by comparing its
 * {@code mimeGroup} and {@code size} with the set defaults either in the application or the
 * component configuration.
 * If its {@code mimeGroup} matches and its {@code size} does not exceed the maximum preview size,
 * it's considered previewable.
 *
 * It returns a function that performs the check on demand, rather than performing it itself.
 *
 * @param {Object} attachment                       the attachment at hand
 * @param {string[]|null} componentPreviewableTypes previewable file types specified by the component that called this
 *                                                  hook. This will take precedence
 * @param {number|null} componentMaxPreviewSize     maximum preview size specified by the component that called this
 *                                                  hook. This will take precedence
 * @returns {function(): *}
 */
const usePreviewableAttachment = ({
  attachment,
  previewableTypes: componentPreviewableTypes = null,
  maxPreviewSize: componentMaxPreviewSize = null,
}) => {
  const {
    session,
    maxPreviewSize: defaultMaxPreviewSize,
    previewableTypes: defaultPreviewableTypes,
  } = useContext(ApplicationContext)

  const mimeGroup = getMimeGroup(attachment.value)
  const mimeType = getMimeType(attachment.value)

  /**
   * Maximum preview size can either be declared on a per component, per action or default basis.
   * @type {number}
   */
  const maxPreviewSizeConfig = componentMaxPreviewSize
      || session[G.MODULE][G.STATE][G.ACTION][G.COMPONENT][G.PROPS]?.maxPreviewSize
      || defaultMaxPreviewSize
  // We can set maxPreviewSize: true, to skip this check
  const maxPreviewSize = isBool(maxPreviewSizeConfig) ? Number.MAX_SAFE_INTEGER : maxPreviewSizeConfig

  /**
   * Types that can be previewed, meaning downloaded immediately. Can be declared on a per component, per action or
   * default basis.
   *
   * @type {string[]|Object<{group: string, types: string[]}>[]}
   */
  const previewableTypes = componentPreviewableTypes
      || session[G.MODULE][G.STATE][G.ACTION][G.COMPONENT][G.PROPS]?.previewableTypes
      || defaultPreviewableTypes

  const mutualTypes = previewableTypes
    .map(x => typesConfig.find(y => y.group === x) || x)
    .filter(x => (isObj(x) ? true : Object.keys(fileConfig.types).includes(x)))

  /**
   * How do we determine if a type is previewable? There are multiple possible scenarios
   * - If we are dealing with an attachment, we have information about its mimetype (like {@code video/mp4})
   * - If we are dealing with an url, we have its url (like {@ code http://.../video.mp4})
   *
   * On the other hand, we have {@link previewableTypes} set, either by using application defaults / action defaults
   * or set individually when calling this hook. The types look like this: {@code ['video', 'image', 'txt']}
   * So they can include mime groups as well as mime types.
   *
   * In addition to string, like 'txt', 'video', ..., {@link previewableTypes} may also contain objects, like
   * `{group: 'text', types: ['!csv', 'txt', ...]}`.
   *
   * - Mapping process for internal attachments (meaning attachments that either reside locally in the client or on the
   * server, and we have an attachment document for it):
   *   {@link previewableTypes} can either contain string (simple type), or objects (complex type):
   *   - Simple type: We'll look if either one of the strings matches the attachments' mime group
   *   - Complex type: We'll look up the group of the complex type (it's `group` property) in {@link fileConfig}, if
   *     present, we'll parse its `type` property, if one of the types is prepended with a `!`, it means it exclude
   *     that file type
   *
   * - Mapping process for external attachments (meaning we only have a URL pointing to it):
   *   We only have its extension ({@code txt}, {@code mp4}, ...). We can use
   *   {@link fileConfig} to look if one of the {@link previewableTypes} is a mime group also listed in {@link fileConfig}
   *   and if so, see if the extension we have is part of that specific group. We do exactly that in
   *   {@link canBePreviewed}. This works fine for videos. Because in this case, we use {@code react-player} to
   *   play the video, and we assume that the player can play every extension listed in {@link fileConfig.types.video}.
   *   However, this doesn't work for documents. If we add {@code 'doc'} to {@link previewableTypes}, that means that
   *   every extension listed in {@link fileConfig.types.doc} is considered previewable. This is not true, e.g.
   *   we can't display word documents. So we have to be careful what we add to {@link previewableTypes}.
   *   {@code ['video', 'image']} is generally fine, but for other file types (like {@code 'txt'}), we should add the
   *   mime type (not group) to {@link previewableTypes}, like {@code ['video', 'image', 'txt']} to make sure we
   *   check against that specific type and not the group.
   *
   */
  const canBePreviewed = mutualTypes.reduce((acc, key) => {
    if (acc) return acc
    const isComplexType = isObj(key)
    const group = isComplexType ? fileConfig.types[key.group] : fileConfig.types[key]

    return group
      ? isComplexType
        ? parseComplexType(key, mimeType, group)
        : group.includes(mimeType)
      : acc
  }, false)

  return () => (parseInt(attachment.value?.size || 0, 10) / 1024 <= maxPreviewSize && canBePreviewed)
      || attachment?.value?.name === 'avatar'
}

export default usePreviewableAttachment
